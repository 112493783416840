body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.emphasize {
  width: 5%;
  height: auto;
}

a {
  text-decoration: none;
}

.image-credits {
  list-style-type: none;
  color: blue;
  font-size: 2em;
}

.footer {
  position: relative;
  margin-top: -200px;
  height: 100px;
  clear: both;
  padding-top: 20px;
}

h1 {
  font-family: 'Impact';
  text-transform: uppercase;
}

h2 {
  font-family: 'Impact';
  text-transform: uppercase;
  color: black;
}

h3 {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.navbar {
  background-color: #f5c242;
}

.navbar-brand {
  font-size: 3em;
  font-family: impact;
}

.nav-item {
  color: black;
  font-family: impact;
  font-size: 1em;
}

.footer-nav-item {
  color: white;
  font-family: impact;
}

.btn-back-nav {
  background-color: #f5c242;
}

.btn-back-nav:hover {
  background-color: #f5c242;
  box-shadow: 0 10px 20px 0 gray;
}

.hero {
  height: 350px;
  background: #f5c242;
}

.background {
  background-image: url('/public/images/title-background.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}

.title-divider {
  margin: auto;
  border: 0;
  padding-top: 10px;
  height: 3px;
  width: 40%;
  background-image: linear-gradient(
    to right,
    rgba(29, 214, 239, 0),
    rgba(16, 100, 236, 0.89),
    rgba(0, 0, 0, 0)
  );
}

.card-subject {
  width: 27rem;
  height: 21rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  border-radius: 10%;
  background: white;
}

.card-lesson {
  width: 24rem;
  height: 19rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  border-radius: 10%;
  background: white;
}

.card-subject:hover,
.card-lesson:hover {
  box-shadow: 0 8px 16px 0 #f5c242;
}

.subjects-container {
  max-width: 90%;
  margin-bottom: 2em;
}

.subject-lesson-image {
  border-radius: 30%;
}

#lesson-detail-image-container {
  max-width: 30%;
}

.live-link {
  font-weight: bold;
}

.line-break {
  display: block;
  margin-top: 1.5em;
}

.contact-page {
  width: 50%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media only screen and (max-width: 991px) {
  .navbar-nav {
    align-items: flex-end;
  }

  .contact-page {
    width: 90%;
  }
}

@media only screen and (max-width: 553px) {
  .navbar-brand {
    font-size: 1.6em;
    justify-content: space-between;
    margin: 0.5em;
  }

  .card-subject {
    width: 19rem;
    height: 14rem;
  }

  .card-lesson {
    width: 19rem;
    height: 14rem;
  }

  #lesson-detail-image-container {
    max-width: 70%;
  }

  .about-text-p {
    margin-top: 1em;
    text-align: center;
  }

  .image-credits {
    font-size: 1.5em;
  }

  .about-width {
    width: 75%;
  }
}
